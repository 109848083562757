import { getOffset } from './_getOffset'

export function homeHeaderScroll() {
  const container = document.querySelector('.site-header');
  const target = document.querySelector('.header h1');
  const navTrigger = document.querySelector('.js-nav-trigger')
  if (target && document.body.classList.contains('template-home')) {
    let top = ''
    const setSize = () => {
      const containerOffset = getOffset(container)
      const containerOffsetTop = containerOffset.top
      const height = target.offsetHeight/2.5
      top = containerOffsetTop+height;
    }

    const scroller = () => {
      const scTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop
      if (top <= scTop) {
        document.body.classList.add('is-home-header-fixed')
      } else {
        document.body.classList.remove('is-home-header-fixed')
      }
    }

    let lastInnerWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      if(lastInnerWidth != window.innerWidth) {
        lastInnerWidth = window.innerWidth;
        setSize()
        scroller()
      }
    })

    window.addEventListener('scroll', scroller, { passive: true })

    try {
      Typekit.load({
        loading: function () {},
        active: function () {
          setSize()
          scroller()
        },
        inactive: function () {},
      })
    } catch (e) {}

    setInterval(() => {
      setSize()
      scroller()
    }, 1000)

    if (navTrigger) {
      navTrigger.addEventListener('click', function (e) {
        e.preventDefault()
        setSize()
        scroller()
      })
    }

    setSize()
    scroller()

  }
}