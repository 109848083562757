export function Ad() {
  // Window幅でモバイル表示かデスクトップ表示か判定
  const isMobile = window.matchMedia('(max-width: 1023px)').matches;

  let env = 'dev';
  if (location.href.indexOf('https://popeyemagazine.jp') >= 0) {
    env = 'prod';
  } else if ((location.href.indexOf('https://stg.popeyemagazine.jp') >= 0) || (location.href.indexOf('http://popeyemagazine.local') >= 0)) {
    env = 'stg';
  }

  window.googletag = window.googletag || {
    cmd: []
  };

  googletag.cmd.push(() => {
    // window.googletag.pubads().enableSingleRequest()
    // 遅延ロード
    window.googletag.pubads().enableLazyLoad({
      renderMarginPercent: 20,
    });
    // ADが空の場合は閉じる
    window.googletag.pubads().collapseEmptyDivs();
    // 環境ごとのターゲティング
    window.googletag.pubads().setTargeting('popeye_env', [env]);
    // 所属カテゴリごとのターゲティング
    window.googletag.pubads().setTargeting('popeye_category', [AD_CATEGORY]);
    window.googletag.enableServices();
  });

  let index = 0;

  if (AD_CATEGORY !== 'promotion') {
    const targets = document.querySelectorAll('.js-ad:not(.is-set-ad)');
    for (let i = 0; i < targets.length; i++) {
      const id = `ad-${index}`;
      // AD表示要素の属性から表示するADを指定する
      const unit = targets[i].getAttribute('data-ad-unit');
      const device = targets[i].getAttribute('data-ad-device');
      if ((isMobile && device === 'desktop') || (!isMobile && device === 'mobile')) continue;
      const name = adUnit[device][unit].name;
      const size = adUnit[device][unit].size;
      targets[i].id = id;
      googletag.cmd.push(() => {
        googletag.defineSlot(name, size, id).addService(googletag.pubads());
        googletag.display(id);
      });

      targets[i].classList.add('is-set-ad');
      index++;
    }
  }
}