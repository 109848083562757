import { shuffleArray } from './_shuffleArray';

export function bounceImage() {
  function easeOutQuad(t) {
    return t * (2 - t);
  }
  function getEasingFunction(easing) {
    switch (easing) {
      case "easeOutQuad":
        return easeOutQuad;
      default:
        return easeOutQuad;
    }
  }
  
  const canvasContainer = document.querySelector('.bounce-image-container');
  if (canvasContainer) {
    const canvas = canvasContainer.querySelector('canvas.bounce-image');
    if (canvas) {
      const initialShapes = 7;
      const maxShapes = 7;
      const minSpeed = 1;
      const maxSpeed = 2;
      const minSize = 100;
      const maxSize = 300;
      const minSizeRatio = 0.1;
      const maxSizeRatio = 0.35;
      const minInterval = 0;
      const maxInterval = 0;
      const minAnimationTime = 0;
      const maxAnimationTime = 0;
      const easing = "easeOutQuad";
      const minShrinkTime = 0;
      const maxShrinkTime = 0;
      const shrinkEasing = "easeOutQuad";
      const ctx = canvas.getContext('2d');
      let shapes = [];
      let currentIndex = 0;
      let animationId;
      let addRandomShapeInterval;
      let focus = true;

      function setSize() {
        canvas.width = '';
        canvas.height = '';
        canvas.width = canvasContainer.offsetWidth * 2;
        canvas.height = canvasContainer.offsetHeight * 2;
        ctx.scale(2, 2);
      }
      
      function getRandomSize() {
        const sizeByRatio = Math.random() * (maxSizeRatio - minSizeRatio) + minSizeRatio;
        const size = sizeByRatio * canvasContainer.offsetWidth;
        return Math.max(minSize, Math.min(maxSize, size));
      }
      
      function getRandomDirection() {
        const angle = Math.random() * 2 * Math.PI;
        const speed = Math.random() * (maxSpeed - minSpeed) + minSpeed;
        const dx = Math.cos(angle) * speed;
        const dy = Math.sin(angle) * speed;
        return {
          dx,
          dy
        };
      }
      
      function addRandomShape() {
        if (!focus) {
          return;
        }

        if (minInterval == maxInterval && shapes.length >= initialShapes) return;

        const current = currentTownTalk[currentIndex];
        currentIndex = (currentIndex + 1) % currentTownTalk.length;
        if (!current['image_src']) return;
        
        const interval = Math.random() * (maxInterval - minInterval) + minInterval;
        const shrinkingShapes = shapes.filter(shape => shape.shrinking);
        if (shrinkingShapes.length > 0) {
          addRandomShapeInterval = setTimeout(addRandomShape, interval);
          return;
        }
        const sameShape = shapes.find(shape => {
          return shape.image.src === current['image_src'] && shape.linkElement.href === current['image_link'];
        });
        if (sameShape) {
          sameShape.shrink();
        } else if (shapes.length >= maxShapes) {
          const shapeToRemove = shapes.shift();
          shapeToRemove.shrink();
          shapes.unshift(shapeToRemove);
        }
    
        const size = getRandomSize();
        const x = Math.random() * (canvasContainer.offsetWidth - size * 2) + size;
        const y = Math.random() * (canvasContainer.offsetHeight - size * 2) + size;
        const { dx, dy } = getRandomDirection();
        const image = new Image();
        image.src = current['image_src'];
        
        const shape = new Shape(x, y, dx, dy, size, image, current['image_link']);
        
        if (!current['is_exist_post']) shape.linkElement.remove();
        
        shapes.push(shape);
        if (minInterval !== maxInterval) {
          addRandomShapeInterval = setTimeout(addRandomShape, interval);
        }
      }
      
      class Shape {
        constructor(x, y, dx, dy, size, image, link) {
          this.x = x;
          this.y = y;
          this.dx = dx;
          this.dy = dy;
          this.size = size;
          this.image = image;
          this.animationProgress = 0;
          this.animationTime = Math.random() * (maxAnimationTime - minAnimationTime) + minAnimationTime;
          this.shrinking = false;
          this.isAlive = true;
          this.linkElement = document.createElement("a");
          this.linkElement.href = link;
          this.linkElement.className = "shape-link";
          canvasContainer.appendChild(this.linkElement);
      
          this.image.onload = () => {
            this.imageAspectRatio = this.image.width / this.image.height;
          };
        }
      
        draw() {
          ctx.save();
          ctx.beginPath();
          ctx.arc(this.x, this.y, this.size / 2, 0, Math.PI * 2, false);
          ctx.clip();
      
          let drawWidth, drawHeight;
          const aspect = this.imageAspectRatio || 1;
          if (this.size / this.size > aspect) {
            drawWidth = this.size;
            drawHeight = this.size / aspect;
          } else {
            drawWidth = this.size * aspect;
            drawHeight = this.size;
          }
      
          ctx.drawImage(
            this.image,
            this.x - drawWidth / 2,
            this.y - drawHeight / 2,
            drawWidth,
            drawHeight
          );
          ctx.restore();
          this.linkElement.style.left = `${this.x - this.size / 2}px`;
          this.linkElement.style.top = `${this.y - this.size / 2}px`;
          this.linkElement.style.width = `${this.size}px`;
          this.linkElement.style.height = `${this.size}px`;
        }
      
        shrink() {
          this.shrinking = true;
          this.shrinkProgress = 0;
          this.shrinkSpeed = Math.random() * (maxShrinkTime - minShrinkTime) + minShrinkTime;
          this.shrinkEasingFunction = getEasingFunction(shrinkEasing);
        }
      
        update() {
          if (!focus) {
            return;
          }

          this.x += this.dx;
          this.y += this.dy;
          if (this.x + this.size / 2 > canvasContainer.offsetWidth || this.x - this.size / 2 < 0) {
            this.dx = -this.dx;
          }
          if (this.y + this.size / 2 > canvasContainer.offsetHeight || this.y - this.size / 2 < 0) {
            this.dy = -this.dy;
          }
          if (this.shrinking) {
            this.shrinkProgress += 1 / this.shrinkSpeed;
            if (this.shrinkProgress >= 1) {
              this.isAlive = false;
              this.linkElement.remove();
              return;
            }
            if (this.shrinkProgress > 1) {
              this.shrinkProgress = 1;
              this.isAlive = false;
              return;
            }
            const easedProgress = this.shrinkEasingFunction(this.shrinkProgress);
            const currentSize = this.size * (1 - easedProgress);
            ctx.save();
            ctx.beginPath();
            ctx.arc(this.x, this.y, currentSize / 2, 0, Math.PI * 2, false);
            ctx.clip();
      
            let drawWidth, drawHeight;
            const aspect = this.imageAspectRatio || 1;
            if (currentSize / currentSize > aspect) {
              drawWidth = currentSize;
              drawHeight = currentSize / aspect;
            } else {
              drawWidth = currentSize * aspect;
              drawHeight = currentSize;
            }
      
            ctx.drawImage(
              this.image,
              this.x - drawWidth / 2,
              this.y - drawHeight / 2,
              drawWidth,
              drawHeight
            );
            ctx.restore();
            this.linkElement.style.left = `${this.x - currentSize / 2}px`;
            this.linkElement.style.top = `${this.y - currentSize / 2}px`;
            this.linkElement.style.width = `${currentSize}px`;
            this.linkElement.style.height = `${currentSize}px`;
          } else if (this.animationProgress < 1) {
            this.animationProgress += 1 / this.animationTime;
            if (this.animationProgress > 1) {
              this.animationProgress = 1;
            }
            const easingFunction = getEasingFunction(easing);
            const easedProgress = easingFunction(this.animationProgress);
            const currentSize = this.size * easedProgress;
            ctx.save();
            ctx.beginPath();
            ctx.arc(this.x, this.y, currentSize / 2, 0, Math.PI * 2, false);
            ctx.clip();
      
            let drawWidth, drawHeight;
            const aspect = this.imageAspectRatio || 1;
            if (currentSize / currentSize > aspect) {
              drawWidth = currentSize;
              drawHeight = currentSize / aspect;
            } else {
              drawWidth = currentSize * aspect;
              drawHeight = currentSize;
            }
            ctx.drawImage(
              this.image,
              this.x - drawWidth / 2,
              this.y - drawHeight / 2,
              drawWidth,
              drawHeight
            );
            ctx.restore();
            this.linkElement.style.left = `${this.x - currentSize / 2}px`;
            this.linkElement.style.top = `${this.y - currentSize / 2}px`;
            this.linkElement.style.width = `${currentSize}px`;
            this.linkElement.style.height = `${currentSize}px`;
          } else {
            this.draw();
          }
        }
      }
      
      function animate() {
        if (!focus) {
          return;
        }
    
        ctx.clearRect(0, 0, canvasContainer.offsetWidth, canvasContainer.offsetHeight);
        shapes = shapes.filter(shape => shape.isAlive);
        for (const shape of shapes) {
          shape.update();
        }
        animationId = requestAnimationFrame(animate);
      }
      
      function init() {
        shapes.forEach(shape => shape.linkElement.remove());
        shapes = [];
        currentIndex = 0;
        shuffleArray(currentTownTalk);
        cancelAnimationFrame(animationId);
        clearTimeout(addRandomShapeInterval);
        setSize();
        
        for (let i = 0; i <= initialShapes; i++) {
          addRandomShape();
        }
        
        if (minInterval !== maxInterval) {
          addRandomShapeInterval = setTimeout(addRandomShape, minInterval);
        }
        
        animate();
      }
          
      init();
    
      let lastInnerWidth = window.innerWidth;
      let resizeSto = '';
      window.addEventListener("resize", function () {
        clearTimeout(resizeSto);
        resizeSto = setTimeout(() => {
          if (lastInnerWidth != window.innerWidth) {
            lastInnerWidth = window.innerWidth;
            init();
          }
        }, 300);
      });
    
      window.addEventListener('focus', () => {
        focus = true;
        init(); // Re-initiate to resume the animation
      });
    
      window.addEventListener('blur', () => {
        focus = false;
        cancelAnimationFrame(animationId);
        clearTimeout(addRandomShapeInterval);
      });
    }
  }
}