export function clock() {
  const clock = document.querySelectorAll('.js-clock');
  if(clock.length) {
    for (let i = 0; i < clock.length; i++) { 
      const el = clock[i];
      const primaryColor = el.dataset.primaryColor ? el.dataset.primaryColor : '#000000';
      const accentColor = el.dataset.accentColor ? el.dataset.accentColor : 'red';

      function clockInit(el) {
        this.init = function(){
          this.timerId = null;
          this.canvas = el;
          this.ctx = this.canvas.getContext('2d');
          this.width = this.canvas.width;
          this.height = this.canvas.width;
          
          const x = this.ctx;
          x.translate(this.width / 2, this.height / 2); 
          x.scale(this.width / 200, this.height / 200); 
          x.rotate(-Math.PI/2); 
          x.strokeStyle = primaryColor;
          x.fillStyle = "white"; 
          x.lineCap = "butt";  
        }
        this.memoriM = function(){
          const x = this.ctx;
          x.save();
          x.lineWidth = 1; 
          for (let i = 0; i < 60; i++) { 
            x.beginPath(); 
            x.rotate(Math.PI/30); 
            x.moveTo(90,0); 
            x.lineTo(100,0); 
            x.stroke(); 
          } 
          x.restore();
        }
        this.memoriH = function(){
          const x = this.ctx;
          x.save();
          x.lineWidth = 2; 
          for (let i = 0; i < 12; i++) { 
            x.beginPath(); 
            x.rotate(Math.PI/6); 
            x.moveTo(80,0); 
            x.lineTo(100,0); 
            x.stroke(); 
          } 
          x.restore();
        }
        this.h = function(){
          const x = this.ctx;
          x.save();
          x.rotate(Math.PI/6 * (this.datetime.getHours() + this.datetime.getMinutes() / 60)); 
          x.lineWidth = 4; 
          x.beginPath(); 
          x.moveTo(-5, 0); 
          x.lineTo(60, 0); 
          x.stroke(); 
          x.restore();
        }
        this.m = function(){
          const x = this.ctx;
          x.save();
          x.rotate(Math.PI/30 * (this.datetime.getMinutes() + this.datetime.getSeconds() / 60)); 
          x.lineWidth = 2; 
          x.beginPath(); 
          x.moveTo(-5, 0); 
          x.lineTo(80, 0); 
          x.stroke(); 
          x.restore();
        }
        this.s = function(){
          const x = this.ctx;
          x.save();
          x.rotate(Math.PI/30 * this.datetime.getSeconds()); 
          x.strokeStyle = accentColor; 
          x.lineWidth = 1; 
          x.beginPath(); 
          x.moveTo(-10, 0); 
          x.lineTo(80, 0); 
          x.stroke(); 
          x.restore();
        }
        this.ticktack = function(){
          if (!this.canvas) {
            this.init();
          }
          this.datetime = new Date();
          const x = this.ctx;
          x.clearRect(-100, -100, 200, 200);
          this.memoriM();
          this.memoriH();
          this.h();
          this.m();
          this.s();
        }
        this.start = function(){
          if (!this.timerId) {
            const _clock = this;
            _clock.ticktack();
            this.timerId = setInterval(function(){_clock.ticktack();}, 1000);
          }
        }
        this.stop = function() {
          if (this.timerId) {
            clearInterval(this.timerId);
            this.timerId = null;
          }
        }
      }
      const x = new clockInit(el);
      x.start();
      window.addEventListener('resize', () => {
        x.start();
      })
    }
  }
}