import { hoverDynamic } from './hover';

export function todoCalendar() {
  // 今日の日付でカレンダーを初期化
  const today = new Date(todayDate); // 今日の日付
  let selectedDate = today.toISOString().split('T')[0]; // 初期化は今日の日付
  loadCalendar(selectedDate);
  updateSelectedDateDisplay(selectedDate);
  updateTodayButtonState(); // 初期化時に「今日」ボタンの状態をチェック

  // 「今日」ボタンのクリックイベント
  const triggerToday = document.querySelector('.todo-calendar-today');
  if (triggerToday) {
    triggerToday.addEventListener('click', function (e) {
      e.preventDefault();
      // is-disableクラスがついている場合は何もしない
      if (this.classList.contains('is-disable')) return;

      // 今日の日付に必ず移動
      selectedDate = today.toISOString().split('T')[0]; // selectedDateを更新
      loadCalendar(selectedDate);
      updateSelectedDateDisplay(selectedDate);
      updateTodayButtonState(); // 今日に移動した後のボタンの状態を更新
    });
  }

  // 前の月へ
  const triggerPrevMonth = document.querySelector('.todo-calendar-prev-month');
  if (triggerPrevMonth) {
    triggerPrevMonth.addEventListener('click', function (e) {
      e.preventDefault();
      changeMonth(-1);
    });
  }

  // 次の月へ
  const triggerNextMonth = document.querySelector('.todo-calendar-next-month');
  if (triggerNextMonth) {
    document.querySelector('.todo-calendar-next-month').addEventListener('click', function (e) {
      e.preventDefault();
      changeMonth(1);
    });
  }

  function changeMonth(offset) {
    let currentMonth = new Date(selectedDate);
    currentMonth.setMonth(currentMonth.getMonth() + offset);

    // 今日が属する月であれば今日の日付に、そうでなければ1日に is-current を付与
    if (currentMonth.getFullYear() === today.getFullYear() && currentMonth.getMonth() === today.getMonth()) {
      selectedDate = today.toISOString().split('T')[0];
    } else {
      currentMonth.setDate(1);
      selectedDate = currentMonth.toISOString().split('T')[0];
    }

    loadCalendar(selectedDate);
    updateSelectedDateDisplay(selectedDate); // 月を変更した時も表示
    updateTodayButtonState(); // 今日ボタンの状態も更新
  }

  function loadCalendar(date) {
    // カレンダーを表示
    fetchTodos(date);
    fetchCalendar(date);
    
    const currentMonth = document.querySelector('.todo-calendar-current-month');
    if (currentMonth) {
      // 表示中の月を更新
      let selectedDateObj = new Date(date);
      const year = selectedDateObj.getFullYear();
      const month = String(selectedDateObj.getMonth() + 1).padStart(2, '0');
      currentMonth.textContent = `${year}年${month}月`;
    }
  }

  function fetchCalendar(date) {
    const calendarBody = document.querySelector('.todo-calendar tbody');
    if (calendarBody) {
      calendarBody.innerHTML = ''; // カレンダーをクリア

      let selectedDateObj = new Date(date);
      const year = selectedDateObj.getFullYear();
      const month = selectedDateObj.getMonth();

      // 今日の日付を取得して比較用に保存
      const todayDate = today.toISOString().split('T')[0];

      // 月の初日と末日を取得
      const firstDay = new Date(year, month, 1);
      const lastDay = new Date(year, month + 1, 0);
      const daysInMonth = lastDay.getDate();

      // 最初の曜日を取得（月曜始まりに調整）
      let startDay = firstDay.getDay();
      startDay = startDay === 0 ? 6 : startDay - 1; // 日曜が0、月曜が1になるように調整

      // カレンダーを週ごとに表示
      let row = document.createElement('tr');
      for (let i = 0; i < startDay; i++) {
        let emptyCell = document.createElement('td');
        row.appendChild(emptyCell);
      }

      for (let day = 1; day <= daysInMonth; day++) {
        const dayLink = document.createElement('a');
        dayLink.href = '#';
        dayLink.textContent = day;
        const fullDate = `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        dayLink.dataset.date = fullDate;

        const cell = document.createElement('td');

        // 今日の日付または選択された日付に `is-current` クラスを追加
        if (fullDate === todayDate && selectedDate === todayDate) {
          cell.classList.add('is-today'); // 今日の日付には is-today クラスを追加
          dayLink.classList.add('is-current');
        } else if (fullDate === selectedDate) {
          dayLink.classList.add('is-current');
        }

        dayLink.addEventListener('click', function (e) {
          e.preventDefault();
          // 全ての `is-current` クラスをリセット
          document.querySelectorAll('.todo-calendar a').forEach(link => {
            link.classList.remove('is-current');
          });
          // クリックされた日付に `is-current` クラスを追加
          this.classList.add('is-current');
          selectedDate = this.dataset.date; // selectedDateを常に更新
          fetchTodos(selectedDate);

          // 動的に日付を表示
          updateSelectedDateDisplay(selectedDate);
          updateTodayButtonState(); // 今日ボタンの状態を更新
        });

        cell.appendChild(dayLink);
        row.appendChild(cell);

        // 週の終わり（日曜日）の場合は新しい行を追加
        if ((startDay + day) % 7 === 0) {
          calendarBody.appendChild(row);
          row = document.createElement('tr');
        }
      }

      // 残りのセルを埋める
      if (row.children.length > 0) {
        while (row.children.length < 7) {
          let emptyCell = document.createElement('td');
          row.appendChild(emptyCell);
        }
        calendarBody.appendChild(row);
      }
    }
  }

  function fetchTodos(date) {
    // 日付に応じたTODOを表示
    const todoContainer = document.querySelector('.todo-calendar-results');
    if (todoContainer) {
      todoContainer.innerHTML = '<p class="textalign-center mt-2 pb-2">Loading...</p>';

      fetch(todoFetchURL + date)
        .then(response => response.json())
        .then(data => {
          todoContainer.innerHTML = data.content;
          todoContainer.querySelectorAll('a').forEach(el => {
            hoverDynamic(el);
          });
        })
        .catch(error => {
          console.error('Error fetching todos:', error);
          todoContainer.innerHTML = '<p class="textalign-center mt-2 pb-2">エラーが発生しました。</p>';
        });
    }
  }

  function updateSelectedDateDisplay(date) {
    const selectedDate = document.querySelector('.todo-calendar-selected-date');
    if (selectedDate) {
      const selectedDateObj = new Date(date);
      const year = selectedDateObj.getFullYear();
      const month = String(selectedDateObj.getMonth() + 1).padStart(2, '0');
      const day = String(selectedDateObj.getDate()).padStart(2, '0');

      if (
        selectedDateObj.getFullYear() === today.getFullYear() &&
        selectedDateObj.getMonth() === today.getMonth() &&
        selectedDateObj.getDate() === today.getDate()
      ) {
        selectedDate.innerHTML = `${year}年${month}月${day}日<span>本日</span>の予定`;
      } else {
        selectedDate.innerHTML = `${year}年${month}月${day}日の予定`;
      }
    }
  }

  function updateTodayButtonState() {
    const todayButton = document.querySelector('.todo-calendar-today');
    if (todayButton) {
      const selectedDateObj = new Date(selectedDate);

      // 今日の日付が選択されている場合にのみ is-disable クラスを追加
      if (selectedDateObj.getFullYear() === today.getFullYear() &&
        selectedDateObj.getMonth() === today.getMonth() &&
        selectedDateObj.getDate() === today.getDate()) {
        todayButton.classList.add('is-disable');
      } else {
        todayButton.classList.remove('is-disable');
      }
    }
  }
}

todoCalendar();