// Stylesheets
import './assets/scss/site.scss'

import { hover } from './assets/modules/hover'
import { fluidvideo } from './assets/modules/fluidvideo'
import { trigger } from './assets/modules/trigger'
import { accordion } from './assets/modules/accordion'
import { fillHeight } from './assets/modules/fillHeight'
import { clockNumber } from './assets/modules/clockNumber'
import { clock } from './assets/modules/clock'
import { circleMarquee } from './assets/modules/circleMarquee'
import { homeHeaderScroll } from './assets/modules/homeHeaderScroll'
import { stickyHeader } from './assets/modules/stickyHeader'
import { initPhotoSwipeFromDOM } from './assets/modules/photoswipe-init';
import { swiperCarousel } from './assets/modules/swiperCarousel'
import { infoTriangle } from './assets/modules/infoTriangle'
import { marqueeClassic } from './assets/modules/marqueeClassic'
import { map, stickyMap } from './assets/modules/map'
import { scrollNav } from './assets/modules/scrollNav'
import { bounceImage } from './assets/modules/bounceImage'
import { tab } from './assets/modules/tab'
import { Ad } from './assets/modules/ad'
import { Analytics } from './assets/modules/analytics'
import { Newsletter } from './assets/modules/newsletter'
import { todoCalendar } from './assets/modules/todo-calendar'
import { bounceSymbol } from './assets/modules/bounceSymbol'
// import { gathering } from './assets/modules/gathering'

const init = () => {
  trigger()
  accordion();
  fillHeight()
  clockNumber()
  clock()
  circleMarquee()
  homeHeaderScroll()
  hover()
  fluidvideo()
  stickyHeader()
  initPhotoSwipeFromDOM('.js-pswp-container')
  swiperCarousel()
  infoTriangle()
  marqueeClassic()
  map()
  stickyMap()
  scrollNav()
  bounceImage()
  todoCalendar();
  tab();
  Ad()
  Analytics()
  Newsletter()
  bounceSymbol();
  // gathering()
}

init()