export function accordion() {
  const els = document.querySelectorAll('[data-accordion]');
  if (els.length) {
    els.forEach((el) => {
      const accordionName = el.dataset.accordion;
      const trigger = document.querySelector(`[data-accordion-trigger=${accordionName}]`);
      if (trigger) {
        trigger.addEventListener('click', function (e) {
          e.preventDefault();
          if (el.classList.contains('is-active')) {
            el.classList.remove('is-active')
            trigger.classList.remove('is-active')
          } else {
            el.classList.add('is-active')
            trigger.classList.add('is-active')
          }
        });
      }
    });
  }
}