import { setPosTop, resetPosTop } from './posTop';

export function trigger() {
  const navTrigger = document.querySelector('.nav-trigger');
  if(navTrigger) {
    navTrigger.addEventListener('click', function(e) {
      document.body.classList.remove('is-search-active', 'is-drawer-active');
      if(!document.body.classList.contains('is-nav-active')){
        setPosTop();
        document.body.classList.add('is-nav-active');
      } else {
        document.body.classList.remove('is-nav-active');
        resetPosTop();
      }
      e.preventDefault();
    });
  }

  const searchTrigger = document.querySelector('.nav-search');
  const searchInput = document.querySelector('.js-input-search');
  if(searchTrigger) {
    searchTrigger.addEventListener('click', function(e) {
      document.body.classList.remove('is-nav-active', 'is-drawer-active');
      if(!document.body.classList.contains('is-search-active')){
        setPosTop();
        document.body.classList.add('is-search-active');
        if(searchInput) searchInput.focus();
      } else {
        if(searchInput) searchInput.blur();
        document.body.classList.remove('is-search-active');
        resetPosTop();
      }
      e.preventDefault();
    });
  }

  const close = document.querySelectorAll('.trigger-close');
  if(close.length) {
    close.forEach((el, index) => {
      el.addEventListener('click', function(e) {
        resetPosTop();
        document.body.classList.remove('is-search-active', 'is-nav-active', 'is-drawer-active');
        e.preventDefault();
      });
    });
  }
}