export function marqueeClassic() {
  const marquees = document.querySelectorAll('.js-marquee-classic');

  marquees.forEach(marquee => {
    const marqueeContainer = marquee.querySelector('.marquee-classic-container');
    if(marqueeContainer) {
      const marqueeContent = marquee.querySelector('.marquee-classic-content');
      if(marqueeContent) {
        const marqueeElements = marqueeContent.querySelectorAll('.marquee-classic-element');
        if(marqueeElements.length) {

          const _marqueeContent = marqueeContent.cloneNode(true);
          marqueeContainer.appendChild(_marqueeContent)

          let speed = 1;
          let direction = -1;
          let pause = false;
          let isPause = false;

          if(marquee.dataset.speed) speed = Number(marquee.dataset.speed);
          if(marquee.dataset.direction == 'left') direction = -1;
          if(marquee.dataset.direction == 'right') direction = 1;
          if(marquee.dataset.pause == 'true') isPause = true;
          if(marquee.dataset.pause == 'false') isPause = false;

          let currentPosition = 0;
          let w = marqueeContent.offsetWidth

          function updatePosition() {
            currentPosition += speed * direction;

            // コンテンツが左端または右端に到達した場合のリセット
            if (direction == -1 && -currentPosition >= w) {
              currentPosition = 0;
            } else if (direction == 1 && currentPosition >= w) {
              currentPosition = 0;
            }

            marqueeContainer.style.transform = `translateX(${currentPosition}px)`;
          }
      
          function animate() {
            if(!pause) updatePosition();
            requestAnimationFrame(animate);
          }
      
          if(isPause) {
            // ホバー時にアニメーションを一時停止
            marquee.addEventListener('mouseover', () => pause = true);
            marquee.addEventListener('mouseout', () => pause = false);
          }

          let lastInnerWidth = window.innerWidth ;
          window.addEventListener("resize", function () {
            if(lastInnerWidth != window.innerWidth) {
              lastInnerWidth = window.innerWidth;
              setTimeout(() => {
                w = marqueeContent.offsetWidth
              }, 0)
            }
          });

          setInterval(() => {
            w = marqueeContent.offsetWidth
          }, 1000)
      
          animate(); // アニメーションの開始

          marquee.classList.add('is-active');
        }
      }
    }
  });
}
