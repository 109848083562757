import Swiper, { Navigation, Pagination, FreeMode } from 'swiper';
Swiper.use([Navigation, Pagination, FreeMode]);

export function swiperCarousel() {
  const homeCarousel = document.querySelectorAll('.js-swiper-carousel');
  if(homeCarousel.length) {
    homeCarousel.forEach((el,index) => {
      const slides = el.querySelectorAll('.swiper-slide');
      if(slides.length > 1) {
        let mySwiper = '';
        let mySwiperBool = '';
        let swiperSpace = 0;

        const wrapper = el.querySelector('.swiper-wrapper');
        const next = el.querySelector('.swiper-button-next');
        const prev = el.querySelector('.swiper-button-prev');
        const count = el.querySelector('.swiper-count');

        let freeModeBool = false;
        const isFreeMode = el.dataset.swiperFreeMode;
        if (isFreeMode == 'true'){
          freeModeBool = true;
        }

        let initialSlideNum = 0;
        const initialSlideIndex = Array.from(slides).findIndex(slide => slide.classList.contains('is-initial-slide'));
        if (initialSlideIndex !== -1) {
          initialSlideNum = initialSlideIndex;
        }

        const getSwiperBool = () => {
          const beforeStr = getComputedStyle(el, ":before").content;
          if(beforeStr == '"swiper"') {
            mySwiperBool = true;
            const updateSlideCount = (swiper) => {
              const currentSlide = swiper.realIndex + 1;
              const totalSlides = swiper.slides.length;
              if(count) count.textContent = currentSlide + '/' + totalSlides;
            }
            mySwiper = new Swiper(el, {
              navigation: {
                nextEl: next,
                prevEl: prev
              },
              freeMode: {
                enabled: freeModeBool,
                momentumRatio: 0.3,
                momentumVelocityRatio: 0.35
              },
              initialSlide: initialSlideNum,
              slidesPerView: 'auto',
              spaceBetween: 0,
              edgeSwipeThreshold: 100,
              grabCursor: true,
              on: {
                init: function () {
                  updateSlideCount(this);
                },
                slideChange: function () {
                  updateSlideCount(this);
                }
              }
            });
          } else {
            mySwiperBool = false;
            if(mySwiper !== '') {
              mySwiper.destroy(true,true);
              wrapper.removeAttribute('style')
            }
          }
        }
        let lastInnerWidth = window.innerWidth;
        let resizeSto = ''
        window.addEventListener("resize", function () {
          this.clearTimeout(resizeSto);
          resizeSto = setTimeout(() => {
            if(lastInnerWidth != window.innerWidth) {
              lastInnerWidth = window.innerWidth;
              getSwiperBool();
            }
          }, 300)
        });
        getSwiperBool()
      }
    })
  }
}