export function fluidvideo() {
  // List of Video Vendors embeds you want to support
  const players = ['.todo-col-content iframe, .wp-block-embed-vimeo .wp-block-embed__wrapper iframe, .embed iframe'];

  // Select videos
  const fitVids = document.querySelectorAll(players.join(","));

  // If there are videos on the page...
  if (fitVids.length) {
    // Loop through videos
    for (var i = 0; i < fitVids.length; i++) {
      // Get Video Information
      const el = fitVids[i];
      if (el.matches('[src*="embed.music.apple.com"]') || el.matches('[src*="embed.podcasts.apple.com"]')) {
        el.outerHTML = `<div class="embed-apple-music">${el.outerHTML}</div>`;
      } else if (el.matches('[src*="docs.google.com/forms"]')) {
        el.outerHTML = `<div class="embed-google-forms common-border-color">${el.outerHTML}</div>`;
      } else {

        const width = el.getAttribute("width");
        const height = el.getAttribute("height");
        if(width && height && !width.includes('%') && !height.includes('%')) {
          const aspectRatio = height / width;
          const parentDiv = el.parentNode;

          // Wrap it in a DIV
          const div = document.createElement("div");
          div.className = "fitVids-wrapper";
          div.style.paddingBottom = aspectRatio * 100 + "%";
          parentDiv.insertBefore(div, el);
          el.remove();
          div.appendChild(el);

          // Clear height/width from fitVid
          el.removeAttribute("height");
          el.removeAttribute("width");
        }
      }
    }
  }
}