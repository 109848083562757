export function Newsletter() {
  set(document.querySelectorAll('.js-news-letter'));

  function set(targets) {
    for (let i = 0; i < targets.length; i++) {
      NewsLetterInstance(targets[i]);
    }
  }

  function NewsLetterInstance(el) {
    const subscribeUrl = el.dataset.subscribeUrl;
    const unsubscribeUrl = el.dataset.unsubscribeUrl;

    let isUnsubscribe = false;
    let isSending = false;

    const $input = el.querySelector('.js-news-letter-mail-address');
    const $submit = el.querySelector('.js-news-letter-submit');

    // イベントリスナーの設定
    $submit.addEventListener('click', (e) => {
      e.preventDefault();
      submit();
    });

    $input.addEventListener('keydown', (e) => {
      if (e.keyCode === 13) {
        submit();
      }
    });

    // メール送信処理
    function submit() {
      if (isSending) return;

      const email = $input.value;
      if (!email) return;

      const formData = new FormData();
      formData.set('email', email);
      const url = isUnsubscribe ? unsubscribeUrl : subscribeUrl;

      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.text())
        .then((data) => {
          if (data) {
            success();
          } else {
            error();
          }
        })
        .catch(() => {
          error();
        });

      isSending = true;
      $input.value = '送信中';
      sending();
    }

    // 送信中アニメーション
    function sending() {
      if (!isSending) return;
      $input.value += ' .';
      setTimeout(() => {
        sending();
      }, 1000);
    }

    // 成功時の処理
    function success() {
      isSending = false;
      $input.value = '';
      $input.placeholder = `送信完了！${isUnsubscribe ? '解約' : '登録'}完了メールをお送りしました。`;
    }

    // エラー時の処理
    function error() {
      isSending = false;
      $input.value = '';
      $input.placeholder = '送信失敗 再度お試しください。';
    }
  }
}