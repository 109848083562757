export function Analytics() {
  // 初期設定: clickイベントの設定
  setClick(document.querySelectorAll('a:not(.is-set-click)'));
  setClick(document.querySelectorAll('.js-click-event:not(.is-set-click)'));

  window.addEventListener('load', () => {
    const $jsReadingRate = document.querySelectorAll('.js-reading-rate');
    for (let i = 0; i < $jsReadingRate.length; i++) {
      ReadingRate($jsReadingRate[i]);
    }
  });

  // クリックイベントの設定
  function setClick(targets) {
    for (let i = 0; i < targets.length; i++) {
      targets[i].addEventListener('click', clickHandler);
      targets[i].classList.add('is-set-click');
    }
  }

  // クリックイベントハンドラ
  function clickHandler(e) {
    const category = this.dataset.category;
    const label = this.dataset.label || this.href;

    console.log('action: click , category:', category, ', label:', label);
    gtag('event', 'click', {
      event_category: category,
      event_label: label,
      non_interaction: true,
    });
  }
}

// ReadingRate関数
function ReadingRate(el) {
  let reach = 0;
  const countVal = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  let scrollTop, windowHeight, offsetTop, height;

  // リサイズイベント
  function resize() {
    scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    windowHeight = window.innerHeight;
    offsetTop = el.getBoundingClientRect().top + scrollTop;
    height = el.clientHeight;
  }

  // スクロールイベント
  function scroll() {
    if (reach >= countVal.length) return;

    scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;

    const rate =
      (scrollTop / (height + offsetTop - windowHeight)) * 100;

    if (rate >= countVal[reach]) {
      const val = countVal[reach];
      console.log('reading rate:', val + '%');
      gtag('event', 'scroll', {
        event_category: 'reading-rate',
        event_label: val + '%',
        non_interaction: true,
      });
      reach++;
    }
  }

  // イベントリスナーの設定
  window.addEventListener('resize', resize);
  window.addEventListener('scroll', scroll, { passive: true });

  // 初期実行
  resize();
  scroll();
}