import { intRandom } from "./_randNum";
import { shuffleArray } from "./_shuffleArray";
// -----------------------------------------------------------
// 仕様
// ページ内に定義された SVG の <symbol> 要素（図形定義）を使い、指定されたコンテナ内（またはサイト全体の特定領域）にランダムな初期位置で図形を配置します。
// 各図形は上方向に飛ばされ、一定の速度で移動しながらアニメーションします。
// -----------------------------------------------------------
export function bounceSymbol() {
  // -----------------------------------------------------------
  // ① 親要素の取得
  // .site-container 要素を取得し、生成されるすべての図形要素はここに追加する
  // -----------------------------------------------------------
  const siteContainer = document.querySelector(".site-container");

  // -----------------------------------------------------------
  // ② ヘルパー関数: モバイルデバイス判定
  // 画面幅が768px以下の場合、モバイルデバイスとみなす
  // -----------------------------------------------------------
  function isMobileDevice() {
    return window.innerWidth <= 768;
  }

  // -----------------------------------------------------------
  // ③ ヘルパー関数: data属性から数値を取得
  // モバイル用とデスクトップ用の属性を切り替え、存在しない場合は defaultValue を返す
  // type によって整数または浮動小数としてパースする
  // -----------------------------------------------------------
  function getDataAsNumber(el, desktopName, mobileName, defaultValue, type = "int") {
    const isMobile = isMobileDevice();
    let valStr = "";

    // モバイルデバイスの場合は mobileName の data 属性を優先
    if (isMobile && el.dataset[mobileName] !== undefined) {
      valStr = el.dataset[mobileName];
    } else if (el.dataset[desktopName] !== undefined) {
      valStr = el.dataset[desktopName];
    } else {
      return defaultValue;
    }

    const parsed = type === "float" ? parseFloat(valStr) : parseInt(valStr, 10);
    return !isNaN(parsed) ? parsed : defaultValue;
  }

  // -----------------------------------------------------------
  // ④ シンボル定義用 SVG の取得
  // .bounce-symbol-svg 内に定義されている <symbol> 要素を利用して図形を描画する
  // -----------------------------------------------------------
  const symbolSvg = document.querySelector(".bounce-symbol-svg");
  if (!symbolSvg) return;

  // SVG内のすべての <symbol> 要素を配列として取得
  const symbolEls = Array.from(symbolSvg.querySelectorAll("symbol"));
  if (!symbolEls.length) return;

  // 各 <symbol> の id 属性を抽出して配列にする
  const symbolIds = symbolEls.map((sym) => sym.id);

  // -----------------------------------------------------------
  // ⑤ IntersectionObserver の設定
  // 対象のコンテナがビューポート内に入った場合に「visible」クラスを付与し、
  // そうでなければクラスを削除する（生成タイミングなどに利用）
  // -----------------------------------------------------------
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("visible");
      } else {
        entry.target.classList.remove("visible");
      }
    });
  });

  // -----------------------------------------------------------
  // ⑥ 対象コンテナの取得
  // bounce animation の対象となる要素は、.js-bounce-symbol-container クラスが付与された要素
  // -----------------------------------------------------------
  const containers = document.querySelectorAll(".js-bounce-symbol-container");
  if (!containers.length) return;

  // 各コンテナごとに処理を設定
  containers.forEach((container) => {
    // ① コンテナが可視状態になった際の監視を開始する
    observer.observe(container);

    // ② data属性から各種パラメータを取得する
    // 最大同時生成数、生成間隔、移動速度、サイズなど
    const maxCount = getDataAsNumber(container, "max", "mobileMax", 10, "int");
    const showInterval = getDataAsNumber(container, "interval", "mobileInterval", 2000, "int");
    const minSpeed = getDataAsNumber(container, "minSpeed", "mobileMinSpeed", 0.5, "float");
    const maxSpeed = getDataAsNumber(container, "maxSpeed", "mobileMaxSpeed", 2, "float");
    const minSize = getDataAsNumber(container, "minSize", "mobileMinSize", 1, "int");
    const maxSize = getDataAsNumber(container, "maxSize", "mobileMaxSize", 3, "int");

    // range は図形の初期位置のランダム性を制御するパラメータ
    // 0 → 常に中央、1 → コンテナ全体が対象（0～1 にクランプ）
    let range = getDataAsNumber(container, "range", "mobileRange", 0, "float");
    range = Math.min(Math.max(range, 0), 1);

    // -----------------------------------------------------------
    // ⑦ シンボルID のシャッフル設定
    // ランダムな順序でシンボルを生成するため、シャッフル済み配列とインデックスを保持する
    // -----------------------------------------------------------
    let shuffled = shuffleArray([...symbolIds]);
    let currentIndex = 0; // 現在のシャッフル配列内のインデックス
    let objects = []; // 画面上に生成された図形オブジェクトを保持する配列 { el, dx, dy }

    // -----------------------------------------------------------
    // ⑧ シンボル（図形）オブジェクトを生成して DOM に追加する関数
    // -----------------------------------------------------------
    function createSymbolObject() {
      // シャッフル済み配列からシンボルIDを取得
      const symId = shuffled[currentIndex];
      currentIndex++;
      if (currentIndex >= shuffled.length) {
        // すべてのシンボルを使い切ったら再度シャッフルし、インデックスをリセットする
        shuffled = shuffleArray([...symbolIds]);
        currentIndex = 0;
      }

      // 最大生成数に達している場合は、最も古い図形を削除してから新規生成する
      if (objects.length >= maxCount) {
        siteContainer.removeChild(objects[0].el);
        objects.shift();
      }

      // 対象の <symbol> 要素を ID から取得する
      const symbolEl = document.getElementById(symId);
      if (!symbolEl) return;

      // data-url 属性があれば、図形をリンク化する
      const url = symbolEl.dataset.url || "";

      // -----------------------------------------------------------
      // ⑨ 図形描画用要素の生成
      // url が設定されていれば <a> 要素、それ以外は <svg> 要素を生成する
      // -----------------------------------------------------------
      let elContent;
      if (url) {
        // リンクの場合は <a> 要素を作成し、内部に SVG を配置する
        elContent = document.createElement("a");
        elContent.href = url;
        elContent.target = "_blank";
        elContent.classList.add("bounce-symbol");
      } else {
        // 通常の SVG 要素を名前空間付きで作成する
        elContent = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        elContent.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        elContent.classList.add("bounce-symbol");
      }

      // -----------------------------------------------------------
      // ⑩ viewBox の設定
      // 対象の <symbol> 要素から viewBox 属性を取得（なければデフォルト "0 0 100 100"）
      // -----------------------------------------------------------
      const vb = symbolEl.getAttribute("viewBox") || "0 0 100 100";

      // -----------------------------------------------------------
      // ⑪ <use> 要素を生成して、シンボル参照を設定する
      // リンクの場合は内部に <svg> 要素を配置し、その中に <use> を入れる
      // -----------------------------------------------------------
      if (url) {
        const svgEl = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        svgEl.setAttribute("viewBox", vb);
        const useEl = document.createElementNS("http://www.w3.org/2000/svg", "use");
        useEl.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", `#${symId}`);
        svgEl.appendChild(useEl);
        elContent.appendChild(svgEl);
      } else {
        elContent.setAttribute("viewBox", vb);
        const useEl = document.createElementNS("http://www.w3.org/2000/svg", "use");
        useEl.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", `#${symId}`);
        elContent.appendChild(useEl);
      }

      // -----------------------------------------------------------
      // ⑫ 図形のサイズ設定
      // minSize～maxSize の範囲でランダムなサイズ（rem 単位）を設定する
      // -----------------------------------------------------------
      const size = intRandom(minSize, maxSize);
      elContent.style.width = size + "rem";

      // ⑬ 生成した図形要素を、親コンテナ (.site-container) に追加する
      siteContainer.appendChild(elContent);

      // -----------------------------------------------------------
      // ⑭ 初期位置の計算
      // 図形の初期位置は、コンテナまたは viewport の中心を基準とし、
      // range の値に応じたランダムなずれを加えた位置に配置する
      // -----------------------------------------------------------
      const wW = window.innerWidth;          // viewport の幅
      const wH = window.innerHeight;         // viewport の高さ
      const cW = container.clientWidth;      // コンテナの幅
      const cH = container.clientHeight;     // コンテナの高さ
      const containerRect = container.getBoundingClientRect(); // コンテナの位置・サイズ

      // 横方向 (left) の中心座標とずれ範囲 (rangeX) の計算
      let centerX, rangeX;
      if (wW < cW) {
        // もし viewport の幅がコンテナより狭い場合は、viewport 中心からの相対位置として計算
        centerX = (wW / 2) - containerRect.left;
        rangeX = (wW / 2) * range;
      } else {
        // 通常はコンテナの中心を使用
        centerX = cW / 2;
        rangeX = (cW / 2) * range;
      }

      // 縦方向 (top) の中心座標とずれ範囲 (rangeY) の計算
      let centerY, rangeY;
      if (wH < cH) {
        centerY = (wH / 2) - containerRect.top;
        rangeY = (wH / 2) * range;
      } else {
        centerY = cH / 2;
        rangeY = (cH / 2) * range;
      }

      // 図形要素自体の実際のサイズを取得
      const eW = elContent.clientWidth;
      const eH = elContent.clientHeight;

      // ランダムなずれを左右・上下に加えて、初期位置を計算
      // (Math.random() * 2 - 1) は -1～+1 の乱数を生成する
      const computedLeft = (Math.random() * 2 - 1) * rangeX + centerX - eW / 2;
      let computedTop = (Math.random() * 2 - 1) * rangeY + centerY - eH / 2;

      // -----------------------------------------------------------
      // ⑮ 要求仕様: 図形の下端がコンテナの下部を超えないよう調整
      // containerBottom = container の上端位置 + container の高さ
      // もし (computedTop + 図形の高さ) > containerBottom なら、computedTop を containerBottom - 図形の高さ に補正
      // -----------------------------------------------------------
      const containerBottom = container.offsetTop + container.clientHeight;
      if (computedTop + eH > containerBottom) {
        computedTop = containerBottom - eH;
      }

      // 最終的な初期位置を CSS の transform プロパティで設定
      elContent.style.transform = `translate(${computedLeft}px, ${computedTop}px)`;

      // -----------------------------------------------------------
      // ⑯ 図形の移動パラメータ設定
      // 図形は上方向（π～2π の角度）に向けてランダムに飛ばされる
      // dx, dy はそれぞれ水平・垂直方向の移動量
      // -----------------------------------------------------------
      const angle = Math.random() * Math.PI + Math.PI;  // π～2π の範囲の角度（上方向）
      const speed = Math.random() * (maxSpeed - minSpeed) + minSpeed; // minSpeed～maxSpeed のランダムな速度
      let dx = speed * Math.floor(Math.cos(angle));
      let dy = speed * Math.floor(Math.sin(angle));

      // もし dx や dy が 0 になってしまった場合は、最低でも 1 ピクセル分移動するよう補正
      if (dx === 0) dx = 1;
      if (dy === 0) dy = 1;

      // 生成した図形要素と移動パラメータを、オブジェクト配列に追加する
      objects.push({ el: elContent, dx, dy });
    }

    // -----------------------------------------------------------
    // ⑰ アニメーション処理
    // 毎フレーム、各図形オブジェクトの位置を更新し、コンテナ外に出たものは削除する
    // -----------------------------------------------------------
    function animate() {
      const containerRect = container.getBoundingClientRect();

      // 配列内の各図形オブジェクトに対して移動処理を実行
      for (let i = objects.length - 1; i >= 0; i--) {
        const obj = objects[i];
        const rect = obj.el.getBoundingClientRect();

        // 現在の図形の位置（rect）から、コンテナ内での相対位置に移動量 (dx, dy) を加算
        const left = rect.left - containerRect.left + obj.dx;
        const top = rect.top - containerRect.top + obj.dy;
        obj.el.style.transform = `translate(${left}px, ${top}px)`;

        // もし図形がコンテナから完全に外れてしまった場合は、DOM から削除し配列からも取り除く
        if (
          rect.bottom < containerRect.top ||
          rect.top > containerRect.bottom ||
          rect.right < containerRect.left ||
          rect.left > containerRect.right
        ) {
          siteContainer.removeChild(obj.el);
          objects.splice(i, 1);
        }
      }

      // 次のフレームで再び animate 関数を呼び出す（ループ）
      requestAnimationFrame(animate);
    }

    // -----------------------------------------------------------
    // ⑱ 初期化処理
    // 既存の図形オブジェクトを削除し、状態をリセットした後、最初の図形を生成する
    // -----------------------------------------------------------
    function init() {
      // 既存の図形オブジェクトをすべて DOM から削除
      objects.forEach((o) => {
        if (o.el.parentNode === siteContainer) {
          siteContainer.removeChild(o.el);
        }
      });
      // オブジェクト配列を初期化
      objects = [];

      // シンボルID のシャッフル済み配列とインデックスをリセット
      shuffled = shuffleArray([...symbolIds]);
      currentIndex = 0;

      // 初回は1つの図形オブジェクトを生成する
      createSymbolObject();
    }

    // -----------------------------------------------------------
    // ⑲ 生成タイミングや再生成のための設定
    // -----------------------------------------------------------
    let focus = true; // ウィンドウのフォーカス状態を管理（非フォーカス時は生成停止）

    // ランダムなディレイ後に初期化と生成開始
    setTimeout(() => {
      init();

      // 定期的に新しい図形オブジェクトを生成するタイマーを設定
      setInterval(() => {
        if (focus) {
          // コンテナが可視状態（.visible クラス付与）なら生成する
          if (container.classList.contains("visible")) {
            createSymbolObject();
          } else {
            // 非可視の場合は、既存の図形をすべて削除してリセットする
            objects.forEach((o) => {
              siteContainer.removeChild(o.el);
            });
            objects = [];
          }
        }
      }, showInterval);

      // アニメーションループの開始
      animate();

      // ウィンドウのリサイズ時に再初期化（例：SP/PC 切り替え時）
      let lastW = window.innerWidth;
      window.addEventListener("resize", () => {
        if (lastW !== window.innerWidth) {
          init();
          lastW = window.innerWidth;
        }
      });
    }, intRandom(100, 2000)); // intRandom によるランダムなディレイ時間（100～2000ミリ秒）
  });
}