export function clockNumber() {
  const yearEls = document.querySelectorAll('.js-year');
  const monthEls = document.querySelectorAll('.js-month');
  const dayEls = document.querySelectorAll('.js-day');
  const weekEls = document.querySelectorAll('.js-week');
  const weekShortEls = document.querySelectorAll('.js-week-short');
  const weekEnShortEls = document.querySelectorAll('.js-week-en-short');
  const timeEls = document.querySelectorAll('.js-time');
  if (yearEls.length || monthEls.length || dayEls.length || weekEls.length || weekShortEls.length || timeEls.length) {

    function zenkaku2Hankaku(str) {
      return str.replace(/[A-Za-z0-9]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
      });
    }

    // 0~9の数値に0を足して2桁にする関数(例: 0:0:0 -> 00:00:00)
    function formatTime(val) {
      return ("0" + val).slice(-2);
    }

    const getDateTime = () => {
      // 今日の日付データをcurrentDateに格納
      const currentDate = new Date();

      // 年・月・日・曜日を取得
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const date = currentDate.getDate();
      const day = currentDate.getDay();
      const weekday = ["日曜日","月曜日","火曜日","水曜日","木曜日","金曜日","土曜日"];
      const weekdayShort = ["日", "月", "火", "水", "木", "金", "土"];
      const weekdayEnShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

      // 時間を取得
      const hour = zenkaku2Hankaku(formatTime(currentDate.getHours()));
      const minute = zenkaku2Hankaku(formatTime(currentDate.getMinutes()));
      const second = zenkaku2Hankaku(formatTime(currentDate.getSeconds()));

      if (yearEls.length) {
        yearEls.forEach((el,index)=> {
          el.innerText = year;
        })
      }
      if (monthEls.length) {
        monthEls.forEach((el, index) => {
          el.innerText = month;
        })
      }
      if (dayEls.length) {
        dayEls.forEach((el, index) => {
          el.innerText = date;
        })
      }
      if (weekEls.length) {
        weekEls.forEach((el,index) => {
          el.innerText = zenkaku2Hankaku(`${weekday[day]}`);
        })
      }
      if (weekShortEls.length) {
        weekShortEls.forEach((el, index) => {
          el.innerText = zenkaku2Hankaku(`${weekdayShort[day]}`);
        })
      }
      if (weekEnShortEls.length) {
        weekEnShortEls.forEach((el, index) => {
          el.innerText = weekdayEnShort[day];
        })
      }
      if (timeEls.length) {
        timeEls.forEach((el,index) => {
          el.innerText = `${hour}:${minute}:${second}`;
        })
      }
    }
    setInterval(getDateTime,1000);
  }
}