export function circleMarquee() {
  const wrap = document.querySelectorAll('.js-circle-marquee-wrap');
  if (wrap.length) {
    wrap.forEach((el, index) => {
      const circle = el.querySelector('.js-circle-marquee');
      const tp1 = el.querySelector('.tp1');
      const tp2 = el.querySelector('.tp2');
      const tp3 = el.querySelector('.tp3');
      const tp4 = el.querySelector('.tp4');
      if (circle) {
        function Init() {
          let w = el.clientWidth;
          let h = el.clientHeight;
          circle.setAttributeNS(null, "viewBox", `0 0 ${w}  ${h}`);

          let d = `M${w / 10},${h / 2}A${4 * w / 10},${4 * h / 10} 0 0 0 ${9 * w / 10} ${5 * h / 10} A${4 * w / 10},${4 * h / 10} 0 0 0 ${w / 10} ${5 * h / 10} A${4 * w / 10},${4 * h / 10} 0 0 0 ${9 * w / 10} ${5 * h / 10} A${4 * w / 10},${4 * h / 10} 0 0 0 ${w / 10} ${5 * h / 10}`;

          circlePath.setAttributeNS(null, "d", d);
        }
        let so = 0;
        let so2 = 12.5;
        let so3 = 25;
        let so4 = 37.5;
        function Marquee() {
          requestAnimationFrame(Marquee);
          if (tp1) {
            tp1.setAttributeNS(null, "startOffset", so + "%");
            if (so >= 50) {
              so = 0;
            }
            so += 0.02;
          }
          if (tp2) {
            tp2.setAttributeNS(null, "startOffset", so2 + "%");
            if (so2 >= 50) {
              so2 = 0;
            }
            so2 += 0.02;
          }
          if (tp3) {
            tp3.setAttributeNS(null, "startOffset", so3 + "%");
            if (so3 >= 50) {
              so3 = 0;
            }
            so3 += 0.02;
          }
          if (tp4) {
            tp4.setAttributeNS(null, "startOffset", so4 + "%");
            if (so4 >= 50) {
              so4 = 0;
            }
            so4 += 0.02;
          }
        }
        Init();
        Marquee();
        window.addEventListener("resize", Init, false);
      }
    });
  }
}