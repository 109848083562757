import Cookies from 'js-cookie'

export function infoTriangle() {
  const cook = Cookies.get('po-web-triangle')
  if (cook == null || cook == 0) {
    setTimeout(function () {
      document.body.classList.add('is-info-triangle-active');
    }, 1000)
  }
  const infoTriggerClose = document.querySelector('.js-info-triangle-close')
  if (infoTriggerClose) {
    infoTriggerClose.addEventListener('click', function (e) {
      e.preventDefault()
      Cookies.set('po-web-triangle', '1', { expires: 3 })
      document.body.classList.remove('is-info-triangle-active')
    })
  }
}
