import { getOffset } from './_getOffset'

export function stickyHeader() {
  const stickyNavContainer = document.querySelector('.site-header',)
  const navTrigger = document.querySelector('.js-nav-trigger')
  if (stickyNavContainer) {
    
    let stickyNavContainerOffsetTop = ''
    const setSize = () => {
      const stickyNavContainerOffset = getOffset(stickyNavContainer)
      stickyNavContainerOffsetTop = stickyNavContainerOffset.top
    }

    const scroller = () => {
      const scTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop
      if (stickyNavContainerOffsetTop <= scTop) {
        document.body.classList.add('is-header-fixed')
      } else {
        document.body.classList.remove('is-header-fixed')
      }
    }

    let lastInnerWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      if(lastInnerWidth != window.innerWidth) {
        lastInnerWidth = window.innerWidth;
        setSize()
        scroller()
      }
    })

    window.addEventListener('scroll', scroller, { passive: true })

    try {
      Typekit.load({
        loading: function () {},
        active: function () {
          setSize()
          scroller()
        },
        inactive: function () {},
      })
    } catch (e) {}

    setInterval(() => {
      setSize()
      scroller()
    }, 1000)

    if (navTrigger) {
      navTrigger.addEventListener('click', function (e) {
        e.preventDefault()
        setSize()
        scroller()
      })
    }

    setSize()
    scroller()
  }
}
